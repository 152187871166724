// 
// dropdowns.scss
// Extended from Bootstrap
// 

// 
// Bootstrap Overrides
//
.dropdown-menu {
  box-shadow: $dropdown-box-shadow;
}

.dropdown-item {
  font-weight: $dropdown-item-font-weight;
  text-transform: capitalize;
  position: relative;
}

.dropdown-item.active {
  color: var(--#{$prefix}primary) !important;
}

// Dropdown arrows
// Replace original arrows with Custom icons
// 
.dropdown-toggle {
  &:after {
    content: "\f078";
    font-family: 'Font Awesome 5 Free';
    font-size: 0.5rem;
    margin-left: 0.35rem;
    font-weight: 900;
    vertical-align: middle;
    border: none;
  }
}
.dropend .dropdown-toggle {
  &:after {
    content: "\f054";
    font-family: 'Font Awesome 5 Free';
    font-size: 0.5rem;
    margin-left: 0.35rem;
    font-weight: 900;
    vertical-align: middle;
    border: none;
  }
}
.dropstart .dropdown-toggle {
  &:before {
    content: "\f053";
    font-family: 'Font Awesome 5 Free';
    font-size: 0.5rem;
    margin-left: 0.35rem;
    font-weight: 900;
    vertical-align: middle;
    border: none;
  }
}
.dropup .dropdown-toggle {
  &:after {
    content: "\f077";
    font-family: 'Font Awesome 5 Free';
    font-size: 0.5rem;
    margin-left: 0.35rem;
    font-weight: 900;
    vertical-align: middle;
    border: none;
  }
}

// Dropdown overrides for navbar only
.dropdown-menu .dropdown-toggle {
  &:after {
    position: absolute;
    right: 25px;
    top: 10px
  }
  &:before {
    position: absolute;
    right: 25px;
    top: 10px
  }
}


// 
// Additional style for theme
// 
.dropdown-header {
  font-weight: $dropdown-header-font-weight;
  padding-top: $dropdown-padding-y - .5rem;
  font-size: $dropdown-header-font-size;
  padding-left: $dropdown-padding-y + 1rem;
}

// Dropdown mega menu
.dropdown-fullwidth {
  position: static;
  .dropdown-menu {
    margin: 0 auto;
    left: 0;
    right: 0;
    padding: 1rem 0;
  }
}

// Dropdown fullwidth menu width on responsive
@each $breakpoint, $container-max-width in $container-max-widths {
  @include media-breakpoint-up($breakpoint) {
    .dropdown-fullwidth {
      .dropdown-menu {
        max-width: $container-max-width; 
      }
    }
  }
}

// dropdown menu start end position
// @each $breakpoint in map-keys($grid-breakpoints) {
//   @include media-breakpoint-up($breakpoint) {
//     $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
//     .dropdown-menu#{$infix}-start {
//       left: 0;
//     }
//     .dropdown-menu#{$infix}-end {
//       right: 0;
//     }
//   }
// }

@include media-breakpoint-down(xl) {
  .dropdown-fullwidth .dropdown-menu .container{
    max-width: 100%;
  }
}

// Dropdown menu open on hover after xl
@include media-breakpoint-up(xl) {
  .navbar-expand-xl {
    .navbar-nav {
      .dropdown-menu {
        top: 120%;
        visibility: hidden;
        opacity: 0;
        display: block;
        transition: $transition-base;
      }
      .dropdown {
        &:hover {
          >.dropdown-menu {
            top: 100%;
            visibility: visible;
            opacity: 1;
          }
        }
      }
      .dropdown-submenu {
        &:hover {
          >.dropdown-menu {
            top: 0;
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
  .navbar-expand-xl .dropdown-submenu {
    position: relative;
  }
}


// Dropdown menu open on hover after lg
@include media-breakpoint-up(lg) {
  .navbar-expand-lg {
    .navbar-nav {
      .dropdown-menu {
        top: 120%;
        visibility: hidden;
        opacity: 0;
        display: block;
        transition: $transition-base;
      }
      .dropdown {
        &:hover {
          >.dropdown-menu {
            top: 100%;
            visibility: visible;
            opacity: 1;
          }
        }
      }
      .dropdown-submenu {
        &:hover {
          >.dropdown-menu {
            top: 0;
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
  .navbar-expand-lg .dropdown-submenu {
    position: relative;
  }
}


// Dropdown menu open on hover after md
@include media-breakpoint-up(md) {
  .navbar-expand-md {
    .navbar-nav {
      .dropdown-menu {
        top: 120%;
        visibility: hidden;
        opacity: 0;
        display: block;
        transition: $transition-base;
      }
      .dropdown {
        &:hover {
          >.dropdown-menu {
            top: 100%;
            visibility: visible;
            opacity: 1;
          }
        }
      }
      .dropdown-submenu {
        &:hover {
          >.dropdown-menu {
            top: 0;
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
  .navbar-expand-md .dropdown-submenu {
    position: relative;
  }
}

// Dropdown menu open on hover after sm
@include media-breakpoint-up(sm) {
  .navbar-expand-sm {
    .navbar-nav {
      .dropdown-menu {
        top: 120%;
        visibility: hidden;
        opacity: 0;
        display: block;
        transition: $transition-base;
      }
      .dropdown {
        &:hover {
          >.dropdown-menu {
            top: 100%;
            visibility: visible;
            opacity: 1;
          }
        }
      }
      .dropdown-submenu {
        &:hover {
          >.dropdown-menu {
            top: 0;
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
  .navbar-expand-sm .dropdown-submenu {
    position: relative;
  }
}


// dropdown toggle for offcanvas
.dropdown-toggle-start-icon {
  .dropdown-toggle {
    position: absolute;
    top: 20px;
    left: -40px;
    background: rgba($primary, 0.2);
    border: none;
    width: 34px;
    height: 34px;
    line-height: 34px;
    border-radius: 50%;
    text-align: center;
    padding: 0;
  }
  .collapsed.dropdown-toggle:after {
    content: "\f00d";
    color: $primary;
    font-family: 'Font Awesome 5 Free';
    font-size: 14px;
    top: 5px;
    transform: rotate(45deg);
    transition: $transition-base;
  }
  .dropdown-toggle:after {
    content: "\f00d";
    color: $primary;
    font-family: 'Font Awesome 5 Free';
    font-size: 14px;
    top: 5px;
    transform: rotate(0deg);
    transition: $transition-base;
  }
}