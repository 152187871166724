// Variables
// Bootstrap variable overrides by theme author

// Color system

$white: #fff;
$gray-100: #f7f8f9;
$gray-200: #f0f1f3;
$gray-300: #dfe0e5;
$gray-400: #d0d4d9;
$gray-500: #a1a1a8;
$gray-600: #515155;
$gray-700: #29292e;
$gray-800: #19191c;
$gray-900: #0f0f10;
$black: #000;

$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;

$primary: #ea110c; // Change theme primary color here
$secondary: $gray-400;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-800;

// theme-colors-map
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
);
// scss-docs-end theme-colors-map

// The contrast ratio to reach against white
$min-contrast-ratio: 4.5;

// Spacers

$spacer: 1rem;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  2: (
    $spacer * 0.5,
  ),
  3: $spacer,
  4: (
    $spacer * 1.5,
  ),
  5: (
    $spacer * 3,
  ),
  6: (
    $spacer * 4.5,
  ),
  7: (
    $spacer * 6,
  ),
  8: (
    $spacer * 8,
  ),
  9: (
    $spacer * 11,
  ),
  11: (
    $spacer * 20,
  ),
  10: (
    $spacer * 14,
  ),
);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true;
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-reduced-motion: true;
$enable-smooth-scroll: true;
$enable-grid-classes: true;
$enable-button-pointers: true;
$enable-rfs: true;
$enable-validation-icons: true;
$enable-negative-margins: true;
$enable-deprecation-messages: true;
$enable-important-utilities: true;

// Body

$body-bg: $white;
$body-color: $gray-500;

// Links
$link-color: $primary;
$link-decoration: none;
$link-shade-percentage: 20%;
$link-hover-color: shift-color($link-color, $link-shade-percentage);
$link-hover-decoration: null;

// Paragraphs

$paragraph-margin-bottom: 1rem;

// Grid breakpoints extend

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px,
);

// Define the maximum width of `.container`
// sm: 96%
$container-max-widths: (
  sm: 94%,
  md: 94%,
  lg: 94%,
  xl: 1140px,
  xxl: 1320px,
  xxxl: 1500px,
);

@include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width: 1.875rem; // 30px

// Components

$border-width: 1px;
$border-color: rgba($black, 0.1);

$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;

$box-shadow: 0px 0px 20px rgba(83, 88, 93, 0.15);
$box-shadow-sm: 0 0.125rem 0.25rem rgba(83, 88, 93, 0.15);
$box-shadow-lg: 0 1rem 3rem rgba(83, 88, 93, 0.15);

$component-active-color: $white;
$component-active-bg: $primary;

$transition-base: all 0.3s ease-in-out;

// Badges

$badge-font-weight: $font-weight-normal;
$badge-pill-border-radius: $border-radius;

// Modals pouup

$modal-content-border-width: 0;
$modal-content-border-radius: $border-radius;
$modal-content-inner-border-radius: subtract(
  $modal-content-border-radius,
  $modal-content-border-width
);

// Fonts
//
// Font Families

$font-family-base: "Rubik", sans-serif;
$font-family-heading: "Spartan", sans-serif;
$font-family-alt: "Reenie Beanie", cursive;

// Font Sizes
// $font-size-xs: 0.8125rem; //13px
// $font-size-base: 0.875rem; //14px
$font-size-xs: 0.875rem; //14px
$font-size-base: 0.9375rem; //15px (16px = 1rem)

$font-size-lg: $font-size-base * 1.25;
$font-size-sm: $font-size-base * 0.875;

$font-weight-light: 400;
$font-weight-normal: 500;
$font-weight-bold: 700;

// Line Height

$line-height-base: 1.6;
$line-height-lg: 1.7;
$line-height-sm: 1.4;

// responsive font size Base

$rfs-base-value: 2rem;

// Headings

$h1-font-size: $font-size-base * 4;
$h2-font-size: $font-size-base * 3;
$h3-font-size: $font-size-base * 2;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

// font sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
);

$headings-margin-bottom: 0.5rem;
$headings-font-family: $font-family-heading;
$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.4;
$headings-color: $gray-800;

// display headings
$display-font-sizes: (
  1: 8.75rem,
  2: 7.875rem,
  3: 6.125rem,
  4: 4.8125rem,
  5: 4rem,
  6: 3.2rem,
  7: 2.8rem,
  8: 2rem,
  9: 1.5rem,
  10: 1rem,
);

$display-font-weight: $font-weight-bold;
$display-line-height: $headings-line-height;

// New variables for responsive display classes
$display1-size: 8.75rem;
$display2-size: 7.875rem;
$display3-size: 6.125rem;
$display4-size: 4.8125rem;
$display5-size: 4rem;
$display6-size: 3.2rem;
$display7-size: 2.8rem;
$display8-size: 2rem;
$display9-size: 1.5rem;
$display10-size: 1rem;

$lead-font-size: $font-size-base * 1.25;
$lead-font-weight: $font-weight-light;

$hr-color: $gray-500;

// Buttons + Forms

$input-btn-padding-y: 0.5rem;
$input-btn-padding-x: 1rem;
$input-btn-font-size: $font-size-base;
$input-btn-line-height: $line-height-base;

$input-btn-focus-width: 0;
$input-btn-focus-color: rgba($component-active-bg, 0.25);
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm: 0.4rem;
$input-btn-padding-x-sm: 0.8rem;
$input-btn-font-size-sm: $font-size-base * 0.9;
$input-btn-line-height-sm: $line-height-sm;

$input-btn-padding-y-lg: 0.8rem;
$input-btn-padding-x-lg: 1.5rem;
$input-btn-font-size-lg: 1rem;
$input-btn-line-height-lg: $line-height-lg;

$input-btn-border-width: $border-width;

// Buttons

$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-font-size: $input-btn-font-size;
$btn-line-height: $input-btn-line-height;

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $input-btn-font-size-sm;
$btn-line-height-sm: $input-btn-line-height-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-font-size-lg: $input-btn-font-size-lg;
$btn-line-height-lg: $input-btn-line-height-lg;

$btn-border-width: $input-btn-border-width;

$btn-font-weight: $font-weight-normal;
$btn-box-shadow: none;
$btn-focus-width: $input-btn-focus-width;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;
$btn-disabled-opacity: 0.65;
$btn-active-box-shadow: none;

$btn-link-color: $link-color;
$btn-link-hover-color: $link-hover-color;
$btn-link-disabled-color: $gray-400;

// Button border radius

$btn-border-radius: $border-radius;
$btn-border-radius-lg: $border-radius;
$btn-border-radius-sm: $border-radius;

$btn-transition: $transition-base;

// Forms

$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-font-size: $input-btn-font-size;
$input-line-height: $input-btn-line-height;

$input-padding-y-sm: $input-btn-padding-y-sm;
$input-padding-x-sm: $input-btn-padding-x-sm;
$input-font-size-sm: $input-btn-font-size-sm;
$input-line-height-sm: $input-btn-line-height-sm;

$input-padding-y-lg: $input-btn-padding-y-lg;
$input-padding-x-lg: $input-btn-padding-x-lg;
$input-font-size-lg: $input-btn-font-size-lg;
$input-line-height-lg: $input-btn-line-height-lg;

$input-bg: $white;
$input-disabled-bg: $gray-200;

$input-color: $gray-700;
$input-border-color: $gray-300;
$input-border-width: $input-btn-border-width;
$input-box-shadow: none;

$input-border-radius: $border-radius;
$input-border-radius-xl: $border-radius;
$input-border-radius-lg: $border-radius;
$input-border-radius-sm: $border-radius;

$input-focus-bg: $white;
$input-focus-border-color: $primary;
$input-focus-color: $input-color;
$input-focus-width: $input-border-width;
$input-focus-box-shadow: $input-btn-focus-box-shadow;

$input-placeholder-color: $gray-500;

$input-height-border: $input-border-width * 2;
$input-height: add(
  $input-line-height * 1em,
  add($input-padding-y * 2, $input-height-border, false)
);
$input-height-sm: add(
  $input-line-height-sm * 1em,
  add($input-padding-y-sm * 2, $input-height-border, false)
);
$input-height-lg: add(
  $input-line-height-lg * 1em,
  add($input-padding-y-lg * 2, $input-height-border, false)
);

$form-check-input-bg: $body-bg;
$form-check-input-border: 1px solid rgba(0, 0, 0, 0.25);
$form-check-input-border-radius: 0.25em;
$form-check-radio-border-radius: 50%;
$form-check-input-focus-border: $input-focus-border-color;
$form-check-input-focus-box-shadow: $input-btn-focus-box-shadow;

$form-check-input-checked-color: $component-active-color;
$form-check-input-checked-bg-color: $component-active-bg;
$form-check-input-checked-border-color: $form-check-input-checked-bg-color;

$form-check-input-indeterminate-color: $component-active-color;
$form-check-input-indeterminate-bg-color: $component-active-bg;
$form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg-color;

$form-switch-focus-color: $input-focus-border-color;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>");

$form-select-padding-y: $input-padding-y;
$form-select-padding-x: $input-padding-x;
$form-select-font-size: $input-font-size;
$form-select-font-weight: $input-font-weight;
$form-select-line-height: $input-line-height;
$form-select-color: $input-color;
$form-select-border-radius: $border-radius;

$form-select-focus-border-color: $input-focus-border-color;
$form-select-focus-box-shadow: $input-btn-focus-box-shadow;

$form-range-thumb-bg: $component-active-bg;
$form-range-thumb-active-bg: tint-color($component-active-bg, 70%);
$form-range-thumb-disabled-bg: $gray-500;
$form-range-thumb-box-shadow: 0 0.1rem 0.25rem rgba($black, 0.1);
$form-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg, $input-focus-box-shadow;

// Z-index
$zindex-header: $zindex-sticky;

// Navs

$nav-link-padding-y: 0.25rem;
$nav-link-padding-x: 0.75rem;
$nav-link-font-size: null;
$nav-link-font-weight: null;
$nav-link-color: $gray-700;
$nav-link-hover-color: null;
$nav-link-disabled-color: $gray-500 !important;

$nav-tabs-border-color: $gray-300;
$nav-tabs-border-width: $border-width;
$nav-tabs-border-radius: $border-radius;
$nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color;
$nav-tabs-link-active-color: $gray-700;
$nav-tabs-link-active-bg: $body-bg;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg;

$nav-pills-border-radius: $border-radius;
$nav-pills-link-color: $nav-link-color;
$nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;

// Navbar

$navbar-padding-y: 0;
$navbar-padding-x: 0;

$navbar-nav-link-padding-x: 1rem;
$navbar-nav-link-font-size: $font-size-base;
$navbar-nav-link-font-weight: $font-weight-normal;
$navbar-nav-link-text-transform: capitalize;

$navbar-brand-height: 30px; //Logo height
$navbar-brand-padding-y: 30px;
$nav-link-line-height: $navbar-brand-padding-y * 2 + $navbar-brand-height;

$sticky-navbar-brand-height: 22px; //sticky Logo height
$sticky-navbar-brand-padding-y: 14px;
$sticky-nav-link-line-height: $sticky-navbar-brand-padding-y * 2 +
  $sticky-navbar-brand-height;

$mobile-navbar-brand-height: 22px; //mobile Logo height
$mobile-navbar-brand-padding-y: 20px;

$navbar-toggler-padding-x: 0;
$navbar-toggler-focus-width: $btn-focus-width;

$navbar-dark-color: $white;
$navbar-dark-hover-color: $primary !important;
$navbar-dark-active-color: $primary !important;
$navbar-dark-disabled-color: rgba($white, 0.25);
$navbar-dark-toggler-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#ffffff' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"),
  "#",
  "%23"
);

$navbar-light-color: $gray-600;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;
$navbar-light-disabled-color: rgba($black, 0.3);
$navbar-light-toggler-border-color: rgba($black, 0.1);

$navbar-primary-color: $white;
$navbar-text-primary-hover-color: $gray-800;
$navbar-primary-active-color: $gray-800;
$navbar-primary-disabled-color: rgba($white, 0.25);
$navbar-primary-toggler-icon-bg: str-replace(
  url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#ffffff' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"),
  "#",
  "%23"
);

// Dropdowns

$dropdown-min-width: 15rem;
$dropdown-padding-y: 1rem;
$dropdown-spacer: 0;
$dropdown-font-size: $font-size-base;
$dropdown-color: $body-color;
$dropdown-bg: $white;
$dropdown-border-color: $border-color;
$dropdown-border-radius: 0;
$dropdown-border-width: 0;

$dropdown-divider-bg: $gray-200;
$dropdown-divider-margin-y: $spacer * 0.5;
$dropdown-box-shadow: 0px 20px 30px rgba(83, 88, 93, 0.05),
  0px 0px 30px rgba(83, 88, 93, 0.1);

$dropdown-link-color: $gray-600;
$dropdown-link-hover-color: $primary;
$dropdown-link-hover-bg: transparent;

$dropdown-link-active-color: $primary;
$dropdown-link-active-bg: $dropdown-link-hover-bg;

$dropdown-link-disabled-color: $gray-600;

$dropdown-item-font-weight: $font-weight-light;

$dropdown-item-padding-y: 0.4rem;
$dropdown-item-padding-x: 2rem;

$dropdown-header-color: $gray-800;
$dropdown-header-font-size: $font-size-base;
$dropdown-header-font-weight: $font-weight-bold;
$dropdown-header-padding: $dropdown-padding-y $dropdown-item-padding-x;

$dropdown-dark-color: $gray-300;
$dropdown-dark-bg: $gray-800;
$dropdown-dark-border-color: $dropdown-border-color;
$dropdown-dark-divider-bg: rgba($white, 0.1);
$dropdown-dark-link-color: $dropdown-dark-color;
$dropdown-dark-link-hover-color: $white;
$dropdown-dark-link-hover-bg: $dropdown-link-hover-bg;
$dropdown-dark-link-active-color: $dropdown-link-active-color;
$dropdown-dark-link-active-bg: $dropdown-link-active-bg;
$dropdown-dark-link-disabled-color: $gray-500;
$dropdown-dark-header-color: $gray-500;

// Pagination

$pagination-padding-y: 0.7rem;
$pagination-padding-x: 1rem;
$pagination-padding-y-sm: 0.25rem;
$pagination-padding-x-sm: 0.5rem;
$pagination-padding-y-lg: 0.75rem;
$pagination-padding-x-lg: 1.5rem;

$pagination-color: $gray-600;
$pagination-bg: $white;
$pagination-border-width: $border-width;
$pagination-border-radius: $border-radius;
$pagination-border-color: $gray-300;

$pagination-focus-box-shadow: $input-btn-focus-box-shadow;
$pagination-focus-outline: 0;
$pagination-focus-color: $link-hover-color;
$pagination-focus-bg: $gray-200;

$pagination-hover-color: $white;
$pagination-hover-bg: $primary;
$pagination-hover-border-color: $primary;

$pagination-active-color: $component-active-color;
$pagination-active-bg: $component-active-bg;
$pagination-active-border-color: $pagination-active-bg;

$pagination-disabled-color: $gray-400;
$pagination-disabled-bg: $white;
$pagination-disabled-border-color: $gray-300;

// Cards

$card-spacer-y: 0.75rem;
$card-spacer-x: 1.25rem;
$card-border-radius: $border-radius;
$card-border-width: 0;
$card-border-color: $border-color;
$card-inner-border-radius: subtract($card-border-radius, $card-border-width);
$card-cap-bg: $white;

$card-img-overlay-padding: 1.25rem;

$card-group-margin: $grid-gutter-width * 0.5;

// Accordion
$accordion-color: $body-color;
$accordion-button-active-bg: tint-color($component-active-bg, 90%);
$accordion-button-active-color: shade-color($primary, 10%);

$accordion-button-focus-border-color: none;
$accordion-button-focus-box-shadow: $btn-focus-box-shadow;
$accordion-icon-color: $accordion-color;
$accordion-icon-active-color: $accordion-button-active-color;
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$accordion-button-active-icon-white: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$white}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$accordion-button-active-icon-dark: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$dark}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");

// Tooltips

$tooltip-font-size: 11px;

// Progress bars

$progress-font-size: $font-size-base * 0.75;
$progress-border-radius: $border-radius;
$progress-bar-bg: $primary;

// List group

$list-group-color: $gray-600;
$list-group-bg: transparent;
$list-group-border-radius: $border-radius;
$list-group-active-color: $component-active-color;
$list-group-active-bg: $component-active-bg;
$list-group-active-border-color: $list-group-active-bg;

// Breadcrumbs

$breadcrumb-padding-y: 0.5rem;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding-x: 0.8rem;

$breadcrumb-bg: transparent;
$breadcrumb-divider-color: $gray-500;
$breadcrumb-active-color: $gray-500;
$breadcrumb-divider: quote("\f111");
$breadcrumb-divider-flipped: $breadcrumb-divider;

// Close

$btn-close-focus-shadow: $input-btn-focus-box-shadow;

// Offcanvas

$offcanvas-padding-y: $modal-inner-padding;
$offcanvas-padding-x: $modal-inner-padding;
$offcanvas-horizontal-width: 500px;
$offcanvas-vertical-height: 100vh;
$offcanvas-transition-duration: 0.3s;
$offcanvas-border-color: $modal-content-border-color;
$offcanvas-border-width: $modal-content-border-width;
$offcanvas-title-line-height: $modal-title-line-height;
$offcanvas-bg-color: $modal-content-bg;
$offcanvas-color: $modal-content-color;
$offcanvas-body-backdrop-color: rgba(
  $modal-backdrop-bg,
  $modal-backdrop-opacity
);
$offcanvas-box-shadow: $modal-content-box-shadow-xs;

// New variables folio
// Avatar

$avatar-size-base: 3rem;
$avatar-size-xs: 1.625rem;
$avatar-size-sm: 2.5rem;
$avatar-size-lg: 4rem;
$avatar-size-xl: 5.125rem;
$avatar-size-xxl: 8rem;
$avatar-size-xxxl: 11rem;
