// 
// nav.scss
// Extended from Bootstrap
// 

// 
// Additional style for theme
// 

// Pills
.nav-pills {
  .nav-link {
    cursor: pointer;
    margin-bottom: 15px;
    color: $nav-pills-link-color;
    padding: 0.5rem 1rem;
  }
}

// nav
.nav.flex-column:not(.nav-pills) .nav-link {
  padding-left: 0;
  padding-top: 0.30rem;
  padding-bottom: 0.30rem;
}

// Tab
.tab-content {
  padding: 25px 0;
  margin-bottom: 20px;
}

.nav-tabs {
  .nav-link {
    color: var(--#{$prefix}gray-800);
    padding: 0.5rem 1rem;
    border: none;
  }
  .nav-link.active {
    background: var(--#{$prefix}primary);
    color: var(--#{$prefix}white);
    border: none;
    .nav-link {
      color: var(--#{$prefix}white);
    }
  }
}

// tab line
.nav-tabs.nav-tabs-line {
  border-bottom: solid 2px $gray-200;
  .nav-link {
    border: transparent;
  }
  .nav-item:first-child .nav-link {
    padding-left: 0;
  }
  .nav-link {
    background: transparent;
    border-bottom: 2px solid transparent;
    &.active {
      background: transparent;
      color: $primary;
      border-bottom: 2px solid $primary;
    }
  }
  .nav-item {
    margin-bottom: -2px;
  }
}

// tab bordered
.nav-tabs.nav-tabs-bordered {
  border: 1px solid $gray-200;
  .nav-link {
    border: transparent;
    border-radius: 0;
    &.active {
      background: var(--#{$prefix}primary);
      color: var(--#{$prefix}white);
      border-radius: 0;
    }
  }
}

// nav tabs dark
.nav-tabs.nav-tabs-dark {
  .nav-item .nav-link {
    color: var(--#{$prefix}gray-800);
  }
  .nav-link.active {
    background: var(--#{$prefix}gray-800);
    color: var(--#{$prefix}white);
  }
}