// 
// type.scss
// Extended from Bootstrap
// 

// 
// Additional style for theme
//

// text force color
.text-white-force *:not(.btn) {
  color: var(--#{$prefix}white) !important;
}
 
// text stroke colors
.text-primary-stroke {
  color: transparent !important;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--#{$prefix}primary);
  transition: $transition-base;
}

.text-primary-stroke.text-hover-fill:hover,
a.text-primary-stroke:hover {
  color: var(--#{$prefix}primary) !important;
}

.text-white-stroke {
  color: transparent !important;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--#{$prefix}white);
  transition: $transition-base;
}

.text-white-stroke.text-hover-fill:hover,
a.text-white-stroke:hover {
  color: var(--#{$prefix}white)!important;
}

.text-dark-stroke {
  color: transparent !important;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--#{$prefix}gray-800);
  transition: $transition-base;
}

.text-dark-stroke.text-hover-fill:hover,
a.text-dark-stroke:hover {
  color: var(--#{$prefix}gray-800) !important;
}

.text-light-stroke {
  color: transparent !important;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--#{$prefix}gray-300);
  transition: $transition-base;
}

.text-light-stroke.text-hover-fill:hover,
a.text-light-stroke:hover {
  color: var(--#{$prefix}gray-300) !important;
}

// text stroke size
.text-stroke-md {
  -webkit-text-stroke-width: 2px;
}

.text-stroke-lg {
  -webkit-text-stroke-width: 3px;
}

// Text Shadow 
.text-primary-shadow{
  text-shadow: 0.03em 0.03em 0 $primary;
}

.text-dark-shadow{
  text-shadow: 0.03em 0.03em 0 $dark;
}

.text-white-shadow{
  text-shadow: 0.03em 0.03em 0 $white;
}

.text-light-shadow{
  text-shadow: 0.03em 0.03em 0 $gray-300;
}

//
// Emphasis
//
small,
.small {
  font-weight: $font-weight-light;
}

.smaller {
  font-size: 60%;
  font-weight: $font-weight-normal;
}


// social media colors
// facebook
.bg-facebook{
  background-color: #5d82d1;
  color: $white;
  &:hover{
    background-color: shift-color(#5d82d1, 10%);
    color: $white;
  }
}
.text-facebook{
  color: #5d82d1;
  &:hover{
    color: shift-color(#5d82d1, 10%);
  }
}

// instagram
.bg-instagram{
  background: #c22b72;
  color: $white;
  &:hover{
    background-color: shift-color(#c22b72, 10%);
    color: $white;
  }
}
.text-instagram{
  color: #c22b72;
  &:hover{
    color: shift-color(#c22b72, 10%);
  }
}

// instagram
.bg-instagram-gradient{
  background: radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  background: -webkit-radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  color: $white;
  background-size: 120% 120%;
  background-position: right bottom;
  transition: $transition-base;
  border: 0;
  i{
    // padding: 1px;
  }
  &:hover{
    background: radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    background: -webkit-radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    color: $white;
    background-size: 100% 100%;
    transition: $transition-base;
    background-position: left bottom;
  }
}
.text-instagram-gradient{
  color: transparent;
  background: radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  background: -webkit-radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  background-clip: text;
  -webkit-background-clip: text;
  transition: $transition-base;
  background-size: 120% 120%;
  &:hover{
    color: transparent;
    background: radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    background: -webkit-radial-gradient(circle at 20% 130%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
    background-clip: text;
    -webkit-background-clip: text;
    background-size: 100% 100%;
    transition: $transition-base;
  }
}

// google
.bg-google{
  background-color: #3c7ff1;
  color: $white;
  &:hover{
    background-color: shift-color(#3c7ff1, 10%);
    color: $white;
  }
}
.text-google{
  color: #3c7ff1;
  &:hover{
    color: shift-color(#3c7ff1, 10%);
  }
}

// twitter
.bg-twitter{
  background-color: #40bff5;
  color: $white;
  &:hover{
    background-color: shift-color(#40bff5, 10%);
    color: $white;
  }
}
.text-twitter{
  color: #40bff5;
  &:hover{
    color: shift-color(#40bff5, 10%);
  }
}

// linkedin
.bg-linkedin{
  background-color: #238cc8;
  color: $white;
  &:hover{
    background-color: shift-color(#238cc8, 10%);
    color: $white;
  }
}
.text-linkedin{
  color: #238cc8;
  &:hover{
    color: shift-color(#238cc8, 10%);
  }
}

// pinterest
.bg-pinterest{
  background-color: #e60023;
  color: $white;
  &:hover{
    background-color: shift-color(#e60023, 10%);
    color: $white;
  }
}
.text-pinterest{
  color: #e60023;
  &:hover{
    color: shift-color(#e60023, 10%);
  }
}

// dribbble
.bg-dribbble{
  background-color: #f7659c;
  color: $white;
  &:hover{
    background-color: shift-color(#f7659c, 10%);
    color: $white;
  }
}
.text-dribbble{
  color: #f7659c;
  &:hover{
    color: shift-color(#f7659c, 10%);
  }
}

// youtube
.bg-youtube{
  background-color: #ff0000;
  color: $white;
  &:hover{
    background-color: shift-color(#ff0000, 10%);
    color: $white;
  }
}

.text-youtube{
  color: #ff0000;
  &:hover{
    color: shift-color(#ff0000, 10%);
  }
}

// skype
.bg-skype{
  background-color: #13c1f3;
  color: $white;
  &:hover{
    background-color: shift-color(#13c1f3, 10%);
    color: $white;
  }
}
.text-skype{
  color: #13c1f3;
  &:hover{
    color: shift-color(#13c1f3, 10%);
  }
}

// display font size extend for responsive
@include media-breakpoint-up(sm) {
  .display-sm-1 {
    font-size: $display1-size;
  }

  .display-sm-2 {
    font-size: $display2-size;
  }

  .display-sm-3 {
    font-size: $display3-size;
  }

  .display-sm-4 {
    font-size: $display4-size;
  }

  .display-sm-5 {
    font-size: $display5-size;
  }

  .display-sm-6 {
    font-size: $display6-size;
  }

  .display-sm-7 {
    font-size: $display7-size;
  }

  .display-sm-8 {
    font-size: $display8-size;
  }

}

@include media-breakpoint-up(md) {
  [class*='display-md-']{
    line-height: 1.2;
  }

  .display-md-1 {
    font-size: $display1-size;
  }

  .display-md-2 {
    font-size: $display2-size;
  }

  .display-md-3 {
    font-size: $display3-size;
    line-height: 1.2;
  }

  .display-md-4 {
    font-size: $display4-size;
  }

  .display-md-5 {
    font-size: $display5-size;
  }

  .display-md-6 {
    font-size: $display6-size;
  }

  .display-md-7 {
    font-size: $display7-size;
  }

  .display-md-8 {
    font-size: $display8-size;
  }

}

@include media-breakpoint-up(lg) {
  [class*='display-lg-']{
    line-height: 1.4;
  }

  .display-lg-1 {
    font-size: $display1-size;
  }

  .display-lg-2 {
    font-size: $display2-size;
  }

  .display-lg-3 {
    font-size: $display3-size;
  }

  .display-lg-4 {
    font-size: $display4-size;
  }

  .display-lg-5 {
    font-size: $display5-size;
  }

  .display-lg-6 {
    font-size: $display6-size;
  }

  .display-lg-7 {
    font-size: $display7-size;
  }

  .display-lg-8 {
    font-size: $display8-size;
  }

}

// 
// blockquote
// 

.blockquote {
  position: relative;
  padding-left: 80px;
  .blockquote-footer{
    margin-top: 12px;
  }
  &:before {
    content: '"';
    position: absolute;
    top: 0;
    left: 0;
    font-size: 8rem;
    line-height: 0.8em;
    font-weight: $font-weight-bold;
    color: var(--#{$prefix}primary);
  }
}

// blockquote bg primary
.blockquote.blockquote-primary {
  background: var(--#{$prefix}primary);
  color: var(--#{$prefix}white);
  padding: 20px 20px 20px 90px;
  border-radius: $border-radius;
  * {
    color: var(--#{$prefix}white);
  }
  &:before {
    color: var(--#{$prefix}white) !important;
    top: 20px;
    left: 20px;
  }
}

// blockquote bg dark
.blockquote.blockquote-dark {
  background: var(--#{$prefix}gray-700);
  color: var(--#{$prefix}white);
  padding: 20px 20px 20px 90px;
  border-radius: $border-radius;
  * {
    color: var(--#{$prefix}white);
  }
  &:before {
    top: 20px;
    left: 20px;
  }
}

// blockquote bg light
.blockquote.blockquote-light {
  background:var(--#{$prefix}gray-200);
  color: var(--#{$prefix}gray-700);
  padding: 20px 20px 20px 90px;
  border-radius: $border-radius;
  * {
    color: var(--#{$prefix}gray-700);
  }
  &:before {
    top: 20px;
    left: 20px;
  }
}

// blockquote primary line
.blockquote.blockquote-line {
  padding: 10px 20px;
  border-left: 5px solid $primary;
  &:before {
    display: none;
  }
}