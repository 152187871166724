// 
// reboot.scss
// Extended from Bootstrap
// 
html{
  overflow-x: hidden;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow-x: hidden;
  z-index: 0;
  position: relative;
}

main {
  background-color: var(--#{$prefix}white);
}

header {
  position: relative;
  z-index: $zindex-header;
  background: var(--#{$prefix}white);
}

b,
strong {
  font-weight: $font-weight-bold;
}

a {
  transition: $transition-base;
}

h1 > a,
h2 > a,
h3 > a,
h4 > a,
h5 > a,
h6 > a,
.h1 > a,
.h2 > a,
.h3 > a,
.h4 > a,
.h5 > a,
.h6 > a {
  color: var(--#{$prefix}gray-800);
}

h1 > a:hover,
h2 > a:hover,
h3 > a:hover,
h4 > a:hover,
h5 > a:hover,
h6 > a:hover,
.h1 > a:hover,
.h2 > a:hover,
.h3 > a:hover,
.h4 > a:hover,
.h5 > a:hover,
.h6 > a:hover {
  color: var(--#{$prefix}primary);
}
img {
  max-width: 100%;
  height: auto;
}

video {
  background-size: cover;
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}

iframe {
  border: 0;
}

ul,
ol,
dl {
  list-style-position: inside;
  padding: 0;
  margin: 0;
}

// Section padding top and bottom
section {
  padding-top: ($spacer * 6);
  padding-bottom: ($spacer * 4.8);
}

@include media-breakpoint-down(md) {
  section {
    padding-top: ($spacer * 4);
    padding-bottom: ($spacer * 3);
  }
}

*:focus {
  outline: none !important;
}

// Highlight Color
::selection {
  color: var(--#{$prefix}white);
  background: var(--#{$prefix}primary);
}
.bg-primary::selection {
  color: var(--#{$prefix}primary);
  background: var(--#{$prefix}white);
}