// Should you need to add your custom variable or you need to override our theme variables from _variables.SCSS, then we highly recommend that you add the code into this file so that whenever theme update is available and you update the theme, You will not lose your hard work :)

// Below given example is for how to overrides core variables. Uncomment the code, after compilation, You will see the color scheme changes on frontend.

// User Variables
// Uncomment below code and change primary color to your brand color

/********************************************/
/*                ALLWORKS              		*/
/********************************************/

/********************************************************/
/* ALW - Font family settings				             			*/
/********************************************************/

/*
Header: 
Menu: Noto Sans JP Bold (700) 14pt #FFF
Hero: Noto Sans JP Black (900) 80pt #FFF

Intro Title: Noto Sans JP SemiBold (600) 30pt #9db500
Intro Text:  

Cards Title: Noto Sans JP Black (900) 24pt #FFF
Cards Text: Noto Sans JP Regular (400) 14pt #FFF

Profile Title: Noto Sans JP Black (900) 24pt #9db500
Profile Text: Noto Sans JP Regular (400) 14pt #000

Gallery: Noto Sans JP Bold (700) #FFF 14pt
Gallery name: 

Testimonial: Noto Sans JP Regular (400) 16pt #000
Testimonial name: Noto Sans JP Regular (400) 17pt #000

Servicing Footer: 

Footer Title: Noto Sans JP Bold (700) 14pt #9db500
Footer Link: Noto Sans JP Regular (400) 14pt #FFF

White: #FFF
Green: #9db500
Yellow: #faf305
Black: #000

*/

// Main Logo Font
// Body font
// font-family: 'Raleway', sans-serif;
// font-family: 'Montserrat', sans-serif;
// font-family: 'Noto Sans JP', sans-serif;

// $font-family-base: "Noto Sans JP", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
//$font-family-base: 'Nunito',-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; */

// $font-family-base: "Rubik", sans-serif;
// $font-family-heading: "Spartan", sans-serif;
// $font-family-alt: "Reenie Beanie", cursive;

$font-family-base: "Noto Sans JP", "Roboto", "Helvetica Neue", sans-serif;
$font-family-heading: "Noto Sans JP", "Roboto", "Helvetica Neue", sans-serif;
$font-family-alt: "Noto Sans JP", "Roboto", "Helvetica Neue", sans-serif;

/********************************************************/
/* ALW - Colour settings 					 			*/
/********************************************************/

$green: #73d90f; // #73d90f
$light-green: #9db500;
$yellow: #faf305;
$pale-green: #fafbf3;
$white: #ffffff;
$black: #000000;
$dark-green: #234204;
$wf-ambulance: #05b484;
$green-alt: #9db500;

$grey-50: #fafafa !default;
$grey-100: #f5f5f5 !default;
$grey-200: #eeeeee !default;
$grey-300: #e0e0e0 !default;
$grey-400: #bdbdbd !default;
$grey-500: #9e9e9e !default;
$grey-600: #757575 !default;
$grey-700: #616161 !default; // Primary
$grey-800: #424242 !default;
$grey-900: #212121 !default;
$gray-900: #454444;

$grey: #454444;

$dark: #252525;
$black: #000;
$white: #fff;

$headings-color: $green;
$body-color: $dark;

$h1-font-size: $font-size-base * 2.6;
$h2-font-size: $font-size-base * 1.9;
$h3-font-size: $font-size-base * 1.6;
$h4-font-size: $font-size-base * 1.3;
$h5-font-size: $font-size-base * 1.1;
$h6-font-size: $font-size-base;

$primary: $green;
$primary-light: $green !default;
$primary-dark: $dark !default;

$link-color: $black !important;
$link-hover-color: $green !important;

//$link-hover-color:  darken($link-color, 15%) !important;

/********************************************************/
/* ALW - Navbar overrides:				              	 			*/
/********************************************************/

/* $navbar-dark-color: rgba($white, 0.8);
$navbar-dark-hover-color: rgba($white, 1);
$navbar-dark-active-color: $white;
$navbar-dark-disabled-color: rgba($white, 0.5);
$navbar-dark-toggler-icon-bg: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"),
  "#",
  "%23"
);
$navbar-dark-toggler-border-color: rgba($white, 0.5);
 */

$theme-colors: ();
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
  ),
  $theme-colors
);

$link-color: $primary;
$link-hover-color: shift-color($link-color, $link-shade-percentage);

$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;
$navbar-dark-hover-color: $primary !important;
$navbar-dark-active-color: $primary !important;

$dropdown-link-hover-color: $primary !important;
$dropdown-link-active-color: $primary;
$dropdown-dark-link-hover-bg: $dropdown-link-hover-bg;
$dropdown-dark-link-active-color: $dropdown-link-active-color;
$dropdown-dark-link-active-bg: $dropdown-link-active-bg;

$progress-bar-bg: $primary;

$component-active-bg: $primary;

$pagination-hover-color: $white;
$pagination-hover-bg: $primary;
$pagination-hover-border-color: $primary;
$pagination-active-color: $component-active-color;
$pagination-active-bg: $component-active-bg;
$pagination-active-border-color: $pagination-active-bg;

$list-group-active-color: $component-active-color;
$list-group-active-bg: $component-active-bg;
$list-group-active-border-color: $list-group-active-bg;

$nav-pills-link-active-color: $component-active-color;
$nav-pills-link-active-bg: $component-active-bg;

$input-focus-border-color: $primary;
$form-check-input-focus-border: $input-focus-border-color;
$form-switch-focus-color: $input-focus-border-color;
$form-select-focus-border-color: $input-focus-border-color;

$form-check-input-checked-bg-color: $component-active-bg;
$form-check-input-checked-border-color: $form-check-input-checked-bg-color;
$form-check-input-indeterminate-color: $component-active-color;
$form-check-input-indeterminate-bg-color: $component-active-bg;
$form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg-color;

$form-switch-focus-color: $input-focus-border-color;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>");

$btn-link-color: $link-color;
$btn-link-hover-color: $link-hover-color;

$form-range-thumb-bg: $component-active-bg;
$form-range-thumb-active-bg: tint-color($component-active-bg, 70%);

// Accordion
$accordion-button-active-bg: tint-color($component-active-bg, 90%);
$accordion-button-active-color: shade-color($primary, 10%);
$accordion-icon-color: $accordion-color;
$accordion-icon-active-color: $accordion-button-active-color;
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
