// Should you need to overwrite any of our SCSS code or need to add any custom code, We highly recommend that you add your code into this file, so whenever theme update is available and you update the theme, You will not lose your hard work :)

/*User CSS*/

// Some interesting classes //
/*
text-dark-stroke
text-light-stroke 
text-truncate
font-alt
grayscale


              <div class="bg-white p-5 line-draw-animation h-100">
                <div class="line-draw-inner">
                </div>
              </div>

                  <!-- Card image popup -->
                  <a
                    class="
                      card-element-hover
                      position-absolute
                      end-0
                      top-0
                      bg-primary
                      p-3
                    "
                    href="assets/images/_robinsonconstruction/what-we-do.jpg"
                    data-glightbox
                    data-gallery="portfolio"
                    ><i class="fas fa-expand text-white"></i
                  ></a>
              
*/
/********************************************/
/*                ALLWORKS              		*/
/********************************************/

// @include media-breakpoint-up(sm) { // 576
// @include media-breakpoint-up(md) { // 768
// @include media-breakpoint-up(lg) { // 992
// @include media-breakpoint-up(xl) { // 1200
// @include media-breakpoint-up(xxxl) { // 1600



/*----------------------------------------------------------
// **************** UNIVERSAL STYLES     **************** //
----------------------------------------------------------*/
// 0. ALL SECTIONS //
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-heading;
  font-weight: 800;
  color: $green;
}
p,
li {
  color: $dark;
  font-weight: 400;
}
p {
  font-size: 1rem;
  line-height: 1.5rem;
}
main section ul {
  // list-style: none;
  // width: 200px;
  text-indent: -20px; /* key property */
  margin-left: 20px; /* key property */
}
main section li {
  line-height: 1.2rem;
  margin-bottom: 6px;
}
.bg-white {
  background: #fff;
}
.bg-tp-white {
  background: #ffffff97;
}
.bg-black {
  background: #000;
}
.bg-green {
  background: $pale-green;
}
// .bg-green-gradient {
//   // background: linear-gradient(180deg, #fcb040 30%, #faa234 90%);
// }
.bg-darkgrey {
  background: $dark;
}
.bg-green-alt {
  background: $green-alt;
}
.bg-green-dark {
  background: $dark-green;
}
.bg-wfa {
  background: $wf-ambulance;
}
.text-right {
  text-align: right;
}
.border-divider {
  border-top: 1px solid #ccc;
}
.lead {
  font-size: 1rem;
}
.bold-text {
  font-weight: 800;
}
.back-top {
  background: $green;
}

.hm-btn{
  padding: 12px 50px;
  background:$green;
  color: $black;
  border: 0;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  position: relative;
  overflow: hidden;
  margin-bottom: 6px;
  outline: 0;
  white-space: nowrap;
}
.hm-btn:hover {
  background: $yellow;
  color: $black !important;
}
p.hm-about-btn {
  margin: 30px 0;
}

.container13 {
  max-width: 1320px !important;
}

@include media-breakpoint-up(md) {
  .lead {
    font-size: 1.1rem;
  }
}
// @include media-breakpoint-up(xxl) {
//   .container {
//     max-width: 1300px;
//   }
// }
// .container {
//   max-width: 90%;
// }

// a.btn-primary:hover,
// a.btn-dark:hover {
//   color: #fff !important;
//   background: $green !important;
//   border-color: $green !important;
//   text-decoration: none !important;
// }

//****************************************************//
//              HEADER CONTENT START                  //
//****************************************************//

/*---------------------------/
// 0. HEADER SECTION        //
----------------------------*/

/* Remove box shadow on Navigation */
.navbar-floating .navbar > .container {
  -webkit-box-shadow: none;
  box-shadow: none;
}

/*---------------------------/
// 0.1 CALL BAR SECTION //
----------------------------*/
/*
.core-callbar {
  background-color: $green;
  padding: 0px 40px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.core-item {
  color: $white;
  font-size: 1.25rem;
  padding: 0 10px;
  margin: 0;
  a,
  a:visited,
  a:active {
    color: $white !important;
  }
  a:hover {
    color: $white !important;
    opacity: 0.7;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  i {
    margin-top: 2px;
    font-size: 1.9rem;
  }
}
@include media-breakpoint-up(sm) {
  .core-callbar {
    justify-content: flex-end;
  }
}

@include media-breakpoint-up(lg) {
  .core-callbar {
    padding: 0px 10px;
  }
}
@media (max-width: 480px) {
  .core-item {
    font-size: 1rem;
    padding: 0 8px;
    i {
      font-size: 1.4rem;
    }
  }
}
*/
/*---------------------------/
// 1. BRAND LOGO //
----------------------------*/

/* Adjust Logo padding (was 30px) */
.navbar-brand {
  padding: 7px 10px;
  margin: 0 !important;
}
.navbar-brand .navbar-brand-item {
  height: 60px;
}

/* Adjust Logo height (was 30px) */
.navbar-brand .navbar-brand-item,
header.navbar-sticky-on .navbar-brand {
  padding: 4px 20px !important;
  .navbar-brand-item {
    height: 60px;
  }
}

.hm-login-btn {
  color: $yellow !important;
}
.hm-login-btn:hover {
  color: $green !important;
}


// @include media-breakpoint-down(sm) {
//   #nav-container {
//     max-width: 100%;
//   }
// }

@include media-breakpoint-up(sm) {
  /* Adjust Logo height (was 30px) */
  header.navbar-sticky-on .navbar-brand {
    padding: 4px 20px !important;
    .navbar-brand-item {
      height: 60px;
    }
  }

  .navbar-brand .navbar-brand-item {
    padding: 0 20px !important;
    height: 60px;
  }
}

@include media-breakpoint-up(lg) {
  // #nav-container {
  //   max-width: 1320px;
  // }
  .navbar-brand {
    padding: 27px 0;
  }
  .navbar-brand .navbar-brand-item {
    padding: 0 !important;
  }
}

/*---------------------------/
// 1. FULLSCAPE NAV MENU //
----------------------------*/

#nav-action-panel {
  padding: 1.5rem 1rem 1rem 1rem !important;
}
#nav-services {
  a:hover p {
    color: $green;
  }
}
.nav-service {
  h6 {
    font-size: 16px;
  }
  p {
    font-size: 15px;
    line-height: 20px;
    margin: 0;
  }
}

.nav-projects {
  padding-left: 0rem !important;
  img {
    border: 3px solid #fff;
  }
  h6 {
    font-size: 0.75rem;
  }
  p {
    margin-top: 8px;
    margin-left: 8px;
    font-size: 15px;
  }
}

a.nav-projects:hover {
  img {
    border: 0;
  }
  h6 {
    color: $dark;
  }
  p {
    color: $green !important;
  }
}

.navbar-nav .nav-item a.nav-link {
  color: $white;
  font-weight: 700;
  font-size: 14px;
}
.navbar-nav .nav-item:hover a.nav-link {
  color: $green;
}
.navbar-expand-lg .navbar-nav .nav-item > .dropdown-menu {
  background: $white;
}
.navbar-nav .dropdown > .dropdown-menu {
  a.dropdown-item {
    color: $dark !important;
  }
  a.dropdown-item:hover {
    color: $green !important;
  }
  // li:hover {
  //   background: $light-green;
  // }
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 23px;
  padding-left: 23px;
  font-size: 1rem;
  font-weight: 400;
}
@include media-breakpoint-up(md) {
  #nav-action-panel {
    padding-left: 0 !important;
    padding-bottom: 0.5rem !important;
    h5 {
      margin-top: -3px;
    }
    p {
      margin-top: 0;
    }
    .btn {
      margin-top: -10px;
      margin-bottom: 0;
    }
  }
}
@include media-breakpoint-up(xl) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 30px;
    padding-left: 30px;
  }
  #navbar-full {
    text-align: right;
  }
  .navbar-nav .nav-item:last-child .nav-link {
    padding-right: 30px;
  }
}
@include media-breakpoint-down(lg) {
  .navbar-nav .nav-item a.nav-link {
    color: $dark;
  }
  #navbarCollapse li.nav-item:hover {
    color: $green;
    background-color: $white;
  }
  #navbarCollapse .nav-service h6 {
    color: $black;
  }
  #navbarCollapse .nav-service:hover h6 {
    color: $green;
  }
}

/*---------------------------/
// 2. MOBILE NAV MENU //
----------------------------*/
.navbar { // Change the toggler
  --bs-navbar-toggler-icon-bg: url(
  "data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28250, 250, 250, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
@include media-breakpoint-down(md) {
  .navbar-nav .nav-item a.nav-link {
    color: $dark !important;

  }

  // .navbar-nav .dropdown > .dropdown-menu {
  //   a.dropdown-item {
  //     color: $dark !important;
  //   }
  //   a.dropdown-item:hover {
  //     color: $green !important;
  //   }
  // }

  // .dropdown-menu li:hover {
  //   background: $black;
  // }
  // .dropdown-menu a.dropdown-item,
  // .dropdown-menu a.dropdown-item:hover {
  //   color: $dark !important;
  // }
}
// **************** MAIN BODY CONTENT START **************** //

//****************************************************//
// 1. HERO BANNER SECTION                             //
//****************************************************//

#home-hero {
  // background-image: image-set(
  //   "../../brand/robinson-construction.avif" type("image/avif"),
  //   "../../brand/robinson-construction.jpg.jpg" type("image/jpeg")
  // );
  // background-image: image-set(
  //   "./" type("image/avif")
  //   "./" type("image/jpeg")
  // );
  // background: linear-gradient(
  //     180deg,
  //     rgba(48, 109, 154, 0.1) 60%,
  //     rgb(48, 109, 154, 1) 95%
  //   ),
  // url("../images/_allworks/img/hero.png");
  // background-position: center center;
  // background-repeat: no-repeat;
  // background-size: cover;
  // background: url("../images/_allworks/img/hero.png");
  // url("../img/hero.jpg");
  background: url("../img/hero.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
#home-hero-txt {
  width: 100%;
  max-width: 400px;
  margin-top: 120px;
  margin-left: 20px;
  h1 {
    font-weight: 900;
    font-size: 36px;
    line-height: 46px;
    color: $white;
    text-shadow: 1px 1px #252525;
  }
}
@include media-breakpoint-up(sm) {
  #home-hero img {
    height: 30px;
    margin-bottom: 30px;
  }
  #home-hero-txt {
    margin-top: 180px;
  }
}
@include media-breakpoint-up(md) {
  #home-hero img {
    height: 50px;
    margin-bottom: 40px;
  }
  #home-hero-txt {
    max-width: 600px;
    margin-top: 180px;
    margin-left: 0;
    h1 {
      font-size: 50px;
      line-height: 60px;
    }
  }

}
@include media-breakpoint-up(xl) {
  #home-hero img {
    height: 75px;
    margin-bottom: 50px;
  }
  #home-hero-txt {
    max-width: 900px;
    margin-top: 170px;
    h1 {
      font-size: 80px;
      line-height: 90px;
    }
  }
  

}

//****************************************************//
// 2. INTRO SECTION                                   //
//****************************************************//

#hm-intro {
  padding-top: 4rem;
  padding-bottom: 4rem;
  h2 {
    margin: 0 auto;
    color: $green;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }
  // p {
  //   max-width: 1000px;
  //   margin: 6px auto 0;
  //   font-size: 20px;
  //   line-height: 32px;
  //   text-align: center;
  // }
}
@include media-breakpoint-up(sm) { // 576
  #hm-intro h2 {
    font-size: 24px;
  }
}
@include media-breakpoint-up(lg) { // 768
  #hm-intro h2 {
    max-width: 1040px;
    font-size: 30px;
  }
}

// @include media-breakpoint-up(sm) { // 576
// @include media-breakpoint-up(md) { // 768
// @include media-breakpoint-up(lg) { // 992
// @include media-breakpoint-up(xl) { // 1200
// @include media-breakpoint-up(xxxl) { // 1600

//****************************************************//
// 3. SERVICES PANELS SECTION                         //
//****************************************************//

#hm-services {
  padding-top: 0;
  padding-bottom: 4rem;
  // h2 {
  //   font-size: 1.5rem;
  // }
  // p {
  //   font-size: 1rem;
  //   line-height: 1.3rem;
  // }
  .row {
    --bs-gutter-x: 2.1rem;
  }
  .mb-30 {
    margin-bottom: 2.1rem;
  }
  .card {
    max-width: 640px;
    margin: 0 auto;
  }
  .card-body {
    border-top: 7px solid $green; // $yellow;
  }
  .card-text {
    padding: 10px;
    h4 {
      font-size: 25px;
      font-weight: 900;
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
    }
  }

}

// .pattern-overlay-5 {
//   &::after {
//     content: "";
//     background: url(../images/bg/pattern/05.png);
//     background-position: center center;
//     background-repeat: repeat;
//     position: absolute;
//     top: 0;
//     right: 0;
//     left: 0;
//     bottom: 0;
//     z-index: -1;
//   }
// }

@include media-breakpoint-up(lg) {
  #hm-services .card-text {
    padding: 30px 30px 0;
  }
}

// @include media-breakpoint-up(sm) { // 576
// @include media-breakpoint-up(md) { // 768
// @include media-breakpoint-up(lg) { // 992
// @include media-breakpoint-up(xl) { // 1200
// @include media-breakpoint-up(xxxl) { // 1600


//****************************************************//
// 4. ABOUT SECTION                                   //
//****************************************************//

#hm-about {
  padding: 4rem 0;
  // nothing yet
}
.itf-grid {
  max-width: 580px;
  margin: 0 auto;
  padding-top: 40px;
}
#itf-text {
  display: flex;
  padding-bottom: 20px;
  h3 {
    font-size: 1.5rem;
    margin-bottom: 8px;
  }
  p {
    font-size: 14px;
    line-height: 1.3rem;
  }
}
@media (max-width: 400px) {
  #hm-services h2,
  #itf-text h3 {
    font-size: 1.2rem;
  }
}
@include media-breakpoint-up(sm) {
  #hm-services h2,
  #itf-text h3 {
    font-size: 1.8rem;
  }
}
@include media-breakpoint-up(lg) {
  .itf-grid {
    max-width: initial;
    margin: 0 auto;
    padding: 0;
    display: grid;
    grid-template-columns: auto 540px;
    // grid-template-rows: auto 45px;
    gap: 0 40px;
  }
  #itf-text {
    grid-area: 1/1/2/2;
    align-items: center;
    height: 100%;
    padding-bottom: 0;
  }
  #itf-image {
    grid-area: 1/2/2/3;
    text-align: right;
  }
}

@include media-breakpoint-up(xl) {
  .itf-grid {
    grid-template-columns: auto 730px;
  }
}
@include media-breakpoint-up(xxxl) {
  .itf-grid {
    grid-template-columns: auto 855px;
  }
  .itf-grid {
    gap: 0 60px;
  }
}

// @include media-breakpoint-up(sm) { // 576
// @include media-breakpoint-up(md) { // 768
// @include media-breakpoint-up(lg) { // 992
// @include media-breakpoint-up(xl) { // 1200
// @include media-breakpoint-up(xxxl) { // 1600


//****************************************************//
// 5. GALLERY SECTION                                 //
//****************************************************//

#hm-gallery {
  padding-top: 4rem;
  padding-bottom: 1rem;
  a.bg-tp-white:hover {
    background: #fff;
  }
}
.hm-gallery-hover {
  width: 100%;
  position: relative;
  bottom: 0px;
  margin: 0;
  padding: 0;
}
// #tns1 > .tns-item {
//   padding-right: 4px !important;
// }
  .hm-gallery-cat {
    // padding: 0.65rem;
    h6 {
      font-weight: 700;
      font-size: 14px;
      text-shadow: 1px 1px #444;
      // font-size: 0.75rem;
    }
  }

.hm-gallery-name {
  padding: 0 !important;
  a.btn {
    margin-bottom: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    // background: #fff;
    color: $dark !important;
    font-weight: bold;
    // border: 2px solid #fff;
  }
  a.btn:hover {
    color: $dark !important;
    // border-top: 1px solid $green;
    // border-bottom: 1px solid $green;
    background: #faf305; // rgba(255, 247, 236, 0.9);
    // border: 2px solid $green;
  }
}
.tns-controls [data-controls]:hover {
  background: $green;
}
/*
@media (max-width: 360px) {
  #tns1 > .tns-item {
    width: calc(8%) !important;
    // padding-right: 20px !important;
  }
}
@media (max-width: 480px) {
  .hm-gallery-cat {
    padding: 0.65rem;
    h6 {
      font-size: 0.75rem;
    }
  }
  .hm-gallery-view {
    display: none;
  }
  .hm-gallery-hover {
    width: auto !important;
  }
  .hm-gallery-name {
    padding: 10px !important;
    span.hm-gallery-action {
      display: none;
    }
    a.btn {
      margin-bottom: 0;
    }
  }
}
@media (max-width: 540px) {
  .hm-gallery-name {
    padding: 6px;
    p {
      display: none;
    }
  }
}
*/


// @include media-breakpoint-up(sm) { // 576
// @include media-breakpoint-up(md) { // 768
// @include media-breakpoint-up(lg) { // 992
// @include media-breakpoint-up(xl) { // 1200
// @include media-breakpoint-up(xxxl) { // 1600


//****************************************************//
// 6. TESTIMONIALS SECTION                            //
//****************************************************//

#hm-testimonials {
  padding-top: 4rem;
  padding-bottom: 8rem;
  // .item h6 {
  //   color: $black;
  //   font-size: 1rem;
  //   font-weight: 400;
  //   font-style: italic;
  // }
  // .lead {
  //   font-size: 1rem;
  //   font-style: italic;
  //   color: $green;
  // }
  // .tns-nav {
  //   button,
  //   button:after {
  //     -webkit-box-shadow: inset 0 0 0 2px $green;
  //     box-shadow: inset 0 0 0 2px $green;
  //   }
  //   button:after {
  //     background-color: $green;
  //   }
  // }
  .tiny-slider-inner .item p {
    max-width: 300px;
    text-align: center;
    margin: 0 auto;
  }
}

// #hm-testimonials-ts > div.tns-slide-active {
//   opacity: 50%;
// }
// #hm2-testimonials-ts div.tns-slide-active:nth-of-type(2) {
//   opacity: 100%;
// }

#tns2 > .tns-item {
  font-size: 1rem;
  // width: calc(8%) !important;
  // padding-right: 20px !important;
}
@include media-breakpoint-up(lg) {
  #hm-testimonials-ts .tns-item {
    opacity: 50%;
  }
  #hm-testimonials-ts
    .tns-item:not(.tns-slide-active)
    + .tns-slide-active
    + .tns-slide-active {
    opacity: 100%;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
}

@media (min-width: 460px) {
  // #hm-testimonials .tiny-slider-inner .item p {
  //   max-width: 320px;
  // }
}

@include media-breakpoint-up(sm) { // 576
  #hm-testimonials .tiny-slider-inner .item p {
    max-width: 420px;
  }
}
@include media-breakpoint-up(md) { // 768
  #hm-testimonials .tiny-slider-inner .item p {
    max-width: 520px;
  }
}

  // @media (max-width: 480px) {
  // }
// @include media-breakpoint-up(sm) { // 576
// @include media-breakpoint-up(md) { // 768
// @include media-breakpoint-up(lg) { // 992
// @include media-breakpoint-up(xl) { // 1200
// @include media-breakpoint-up(xxxl) { // 1600



//****************************************************//

//****************************************************//
// FOOTER CONTENT START                               //
//****************************************************//

// 1. FOOTER LOGO BRANDS SECTION //
#footer-logos {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

#footer-logos-col {
  margin-bottom: 30px;
}
.footer-logos-img {
  max-height: initial;
  width: initial;
}
.footer-logos-sml {
  padding: 24px 0;
}
// #footer-certified {
//   padding-top: 30px;
//   border-top: 1px dotted #b1b1b1;
//   padding-left: 2.5rem;
//   img {
//     display: block;
//     margin: 0 auto 30px;
//   }
//   img:last-child {
//     margin: 0 auto;
//   }
// }
// .footer-certified-img {
//   max-height: initial;
//   width: initial;
//   padding: 0 20px;
// }
// #footer-certified-last {
//   padding: 0 0 0 20px !important;
// }

@include media-breakpoint-up(md) {
  #footer-logos-col {
    margin-bottom: 0;
  }
  // #footer-certified {
  //   padding-top: 0;
  //   border-top: none;
  //   border-left: 1px dotted #b1b1b1;
  //   img {
  //     display: inline;
  //     margin: 0 auto;
  //   }
  // }
}


// 1b. FOOTER WELLINGTON FREE AMBULANCE SECTION //
#footer-wfa {
  padding: 0;
}

#footer-wfa-col {
  margin-bottom: 0;
  padding: 0;
}
.footer-wfa-img {
  max-height: initial;
  width: initial;
}
@include media-breakpoint-up(md) {
  #footer-wfa {
    padding: 0 1rem;
  }
}

// 2. FOOTER LINKS SECTION //
#footer-content .container {
  max-width: 90%;
}
.footer-sticky .navbar-brand-item {
  height: 30px;
}
.footer-links i {
  padding-right: 9px;
}
.footer-links h5 {
  color: $green;
  font-size: 14px;
  font-weight: 700;
}
.footer-links li.nav-item {
  font-size: 14px;
  line-height: 14px;
  a {
    font-weight: 400;
    color: $white;
  }
}

.footer-links a:hover,
#footer-copyright li a:hover {
  color: $yellow !important;
  text-decoration: none;
}

#footer-copyright {
  p,
  li a {
    color: $grey-500 !important;
    font-size: 0.8rem;
  }
}
#footer-copyright li a {
  padding: 0.25rem 1.5rem 0.25rem 0;
}
#footer-sop a img {
  padding-left: 10px;
}
#footer-copyright li#footer-sop a:hover {
  color: #fff !important;
}

@media (min-width: 410px) {
  .footer-sticky .navbar-brand-item {
    height: 40px;
  }
}

@include media-breakpoint-up(xl) {
  #footer-content .container {
    max-width: 1000px;
  }
    .footer-sticky .navbar-brand-item {
    height: 60px;
  }
}
@include media-breakpoint-down(md) {
  .footer-links {
    margin: 0 auto;
  }
  #footer-copylinks {
    display: block !important;
    margin: 0 auto;
  }
}

// .footer-sticky .navbar-brand-item {
//   height: 30px;
// }
// @media (min-width: 410px) {
//   .footer-sticky .navbar-brand-item {
//     height: 40px;
//   }
// }
// @include media-breakpoint-up(xl) {
//   .footer-sticky .navbar-brand-item {
//     height: 58px;
//   }
// }


// **************** INNER PAGES **************** //

// A. CASE STUDIES //

// .case-study-element {
//   text-align: left;
//   line-height: 36px;
// }

// @media (max-width: 500px) {
//   h5.case-study-element span.bg-primary {
//     background: none !important;
//     color: $rcl-blue;
//     padding: 0 !important;
//   }
// }

// @include media-breakpoint-up(lg) {
//   .case-study-element {
//     text-align: right;
//   }
// }
